import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoaderComponent } from './common/_components/loader/loader.component';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LoaderComponent, ModalComponent],
  exports: [CommonModule, LoaderComponent, ModalComponent],
})
export class SharedModule {}
