import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BranchQueue } from '../../common';

@Component({
  selector: 'app-view-board-queue-list',
  templateUrl: './queue-list.component.html',
  styleUrls: ['./queue-list.component.scss'],
})
export class ViewBoardQueueListComponent {
  @Input() queues: Array<any> = [];
  @Input() selectedQueue: BranchQueue;
  @Output() onQueueClick = new EventEmitter<BranchQueue>();

  handleQueueClick(queue: BranchQueue) {
    this.onQueueClick.emit(queue);
  }
}
