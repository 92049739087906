import { Component, Input, Output, EventEmitter } from '@angular/core';
import {} from 'stream';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() loading: boolean;
  @Input() contentStyle: Record<string, string> = {};

  @Output() onVisibleChange = new EventEmitter<boolean>();

  closeModal() {
    this.onVisibleChange.emit(false);
  }
}
