<main-header></main-header>

<div class="main-page-container content-container">
  <h1>{{ branchEndpointService?.details?.name }}</h1>
  <h2>
    Select App View
    <div class="auto-redirect-input">
      <input type="checkbox" (click)="handleUseAutoRedirectChange()" [checked]="autoRedirectMode" id="auto-redirect-check" />
      <label class="form-check-label" for="auto-redirect-check"> Use Auto Redirect </label>
    </div>
    <div class="btn btn-primary flex-btn" (click)="handleToFlexClick()" [routerLink]="['/view-board']">Flex</div>
  </h2>
  <div class="clearfix"></div>
  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="device-wrapper">
        <div class="app-view-title">Printer</div>
        <div class="app-view-icon --printer">
          <div class="app-view-sub-icon">
            <span class="--small">USB</span>
            <i class="--usb-connection"></i>
          </div>
        </div>
        <ng-select
          class="custom"
          [options]="printers"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onPrinterSelect($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="device-wrapper">
        <div class="app-view-title">Monitor</div>
        <div class="app-view-icon --monitor"></div>
        <ng-select
          class="custom"
          [options]="monitors"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onMonitorSelect($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="device-wrapper">
        <div class="app-view-title">Multi Queue Monitor</div>
        <div class="app-view-icon --multi-queue-monitor"></div>
        <ng-select
          class="custom"
          [options]="monitors_overviews"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onMonitorOverviewSelect($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="queue device-wrapper">
        <div class="app-view-title">Dynamic Counter Display</div>
        <div class="app-view-icon --name-based-counter-display"></div>
        <ng-select
          class="custom"
          id="name-based-counter-display-id"
          [options]="nameBasedCounterOptions"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onNameBaseCounterSelect($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="queue device-wrapper">
        <div class="app-view-title">Single Counter Display</div>
        <div class="app-view-icon --counter-display">
          <div class="app-view-sub-icon">
            <span>1</span>
            <i class="--single-counter-display"></i>
          </div>
        </div>

        <ng-select
          class="custom"
          id="queue-display-id"
          [options]="queues"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onConsoleQueueSelect($event)"
        >
        </ng-select>
      </div>

      <div class="counter-roof" [ngClass]="{ clear: !isRoofCounter }">
        <div class="app-view-title">Counter Display</div>
        <div class="img-block display-icon"></div>
        <ng-select
          class="custom"
          id="queue-counter-display-id"
          *ngIf="queue"
          [options]="queue.counters"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="loadRoofCounter(queue, $event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="device-wrapper">
        <div class="app-view-title">Single Queue Display</div>
        <div class="app-view-icon --counter-display">
          <div class="app-view-sub-icon">
            <span>1</span>
            <i class="--single-queue-display"></i>
          </div>
        </div>
        <ng-select
          class="custom"
          [options]="recent_queues"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onRecentQueueSelect($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="device-wrapper">
        <div class="app-view-title">Queue Overview</div>
        <div class="app-view-icon --queue-overview"></div>

        <ng-select
          class="custom"
          [options]="queue_overviews"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onQueueOverviewSelect($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="device-wrapper">
        <div class="app-view-title">Feedback Stands</div>
        <div class="app-view-icon --feedback-view"></div>
        <ng-select
          class="custom"
          [options]="feedback_stands"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onFeedbackStandSelect($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="device-wrapper">
        <div class="app-view-title">Single Queue Information</div>
        <div class="app-view-icon --single-queue-info-monitor"></div>
        <ng-select
          class="custom"
          id="queue-display-id"
          [options]="queues"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onSingleQueueInfoSelect($event)"
        >
        </ng-select>
      </div>
    </div>
  </div>
</div>
