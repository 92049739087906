<div class="token-list">
  <div class="token-list-header">
    <div class="token-origin">Type</div>
    <div class="token-number">Number</div>
    <div class="waiting-time">Wait time</div>
    <div class="token-mood">Mood</div>
  </div>
  <div
    *ngFor="let token of tokens; let i = index"
    class="token-list-item slideInUp"
    [@tokenSlideUpPanel]="tokenSliding"
    [@tokenListPanel]="token"
    (@tokenRemovePanel.start)="animTokenRemoveStart($event, token)"
    (@tokenRemovePanel.done)="animTokenRemoveEnd($event)"
    [@tokenRemovePanel]="token"
  >
    <div class="token-origin icon-{{ token.originType }}"></div>
    <div class="token-number">{{ token.tokenNumber }}</div>
    <div class="waiting-time">{{ token.waitTime | readableTime }}</div>
    <div class="mood-icon {{ tokenMood(token) }}-cls"></div>
  </div>
</div>
