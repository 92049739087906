<nav class="navbar navbar-main">
  <div class="content-container">
    <div class="navbar-header">
      <i class="header-qtime-logo"></i>
      Qtime App (.net)
      <span class="navbar-header-version">v{{ version }}</span>
    </div>

    <div *ngIf="showLogout" class="nav navbar-nav navbar-right header-buttons">
      <a href="{{ qtimeAppPrinterUrl }}">To Qtime Printer (Wi-Fi)</a>
      <button (click)="logoutSession()">Logout</button>
    </div>

    <div class="header-burger-menu">
      <span></span>
      <span></span>
      <span></span>

      <div class="header-dropdown-menu">
        <a href="{{ qtimeAppPrinterUrl }}">To Qtime Printer (Wi-Fi)</a>
        <button (click)="logoutSession()">Logout</button>
      </div>
    </div>
  </div>
</nav>
